:root {
  --deskWidth: 1920px;
  --deskSpace: 0;
  --deskMaxWidth: calc(100vw - (2 * var(--deskSpace)));
}
:root {
  --spacePart: 12px;
}
@media (max-width: 1279px) {
  :root {
    --spacePart: 12px;
  }
}
@media (max-width: 767px) {
  :root {
    --spacePart: 12px;
  }
}
:root {
  --spaceUnit: 12px;
}
@media (max-width: 1279px) {
  :root {
    --spaceUnit: 12px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceUnit: 12px;
  }
}
:root {
  --spaceTotal: 24px;
}
@media (max-width: 1279px) {
  :root {
    --spaceTotal: 24px;
  }
}
@media (max-width: 767px) {
  :root {
    --spaceTotal: 24px;
  }
}
.clearfix {
  *zoom: 1;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: '';
}
.clearfix:after {
  clear: both;
}
.button {
  background-color: #000000;
  line-height: 1.2;
  cursor: pointer;
  color: #fff;
  padding: 8px 40px;
  -webkit-appearance: none;
  border-radius: 0;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
}
.button:hover,
.button:focus {
  color: #fff;
  background-color: #000000;
}
.button:active {
  background-color: #000000;
}
div.navi {
  float: left;
}
div.navi > .item {
  float: left;
}
div.navi > .item > .menu {
  display: block;
}
@media (min-width: 1280px) {
  .togglenavigation,
  .mobile-navigation {
    display: none;
  }
}
div.sub1 {
  display: flex;
  flex-direction: column;
}
div.sub1 > .item {
  margin-bottom: 3px;
}
div.sub1 > .item:nth-last-child(3) {
  margin-top: 24px;
}
div.sub1 > .item > .menu {
  font-size: 20px;
  line-height: 1.15;
  letter-spacing: -0.005em;
  font-weight: normal;
  font-family: 'AkkuratLLWeb-Bold', sans-serif;
}
div.sub1 > .item > .menu:hover,
div.sub1 > .item > .menu:focus {
  text-decoration: underline;
}
div.sub1 > .item > .menu.path {
  text-decoration: underline;
}
div.sub2 {
  display: flex;
  flex-direction: column;
  margin-top: 36px;
}
div.sub2 > .item {
  margin-bottom: 3px;
}
div.sub2 > .item > .menu {
  font-size: 20px;
  line-height: 1.15;
  letter-spacing: -0.005em;
  font-weight: normal;
  font-family: 'AkkuratLLWeb-Bold', sans-serif;
}
div.sub2 > .item > .menu:hover,
div.sub2 > .item > .menu:focus {
  text-decoration: underline;
}
div.sub2 > .item > .menu.path {
  text-decoration: underline;
}
#head {
  margin-top: calc(var(--spaceTotal) * 1 + 12px);
}
.wrapper {
  padding-top: 74px;
}
#edit .wrapper {
  padding-top: 140px;
}
.section--header {
  padding: calc(var(--spaceTotal) * 1) calc(var(--spaceTotal) * 1);
}
.homelink {
  font-size: 20px;
  line-height: 1.2;
}
.grid {
  display: grid;
  gap: 24px 24px;
  grid-auto-rows: auto;
  grid-template-columns: [maincontent] 1fr [sidecontent] 370px [navi] 300px [endgrid];
  grid-template-areas: "maincontent sidecontent navi";
  padding: 0 calc(var(--spaceTotal) * 1);
}
.grid__maincontent {
  grid-area: maincontent;
}
.grid__sidecontent {
  position: fixed;
  top: 64px;
  right: 348px;
  width: 370px;
}
.grid__navi {
  position: fixed;
  top: 100px;
  right: 24px;
  width: 300px;
}
.htitle {
  float: left;
  width: 100%;
}
.htitle--navitext,
.htitle--mobile {
  display: none;
}
.part.link .open {
  font-size: 20px;
  line-height: 1.15;
}
h1,
h2 {
  font-size: 20px;
  line-height: 1.15;
}
.layout4 h1 {
  margin-bottom: 3px;
}
.layout4 h1 strong {
  text-decoration: underline;
}
.area--one {
  width: 100%;
}
.area--one > .unit {
  margin-right: 0.78740157%;
  margin-left: 0.78740157%;
  width: 98.42519685%;
}
.area--one .cb-album .body,
.area--one .head,
.area--one .foot,
.area--one .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim {
  width: 48.42519685%;
}
.area--one > .slim .head,
.area--one > .slim .foot,
.area--one > .slim .part {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim.cb-album .body {
  margin-right: 0%;
  margin-left: 0%;
  width: 100%;
}
.area--one > .slim .tiny {
  width: 100%;
}
.area--one {
  width: 101.6%;
  margin-left: -0.8%;
}
.area--one .unit.wide .part.pict * {
  height: 100%;
}
.area--one .unit.wide .part.pict img {
  width: 100%;
  height: 100%;
  max-width: 100% !important;
  max-height: calc(100vh - 115px) !important;
  /* Use vh as a fallback for browsers that do not support Custom Properties */
  max-height: calc((var(--vh, 1vh) * 100) - 115px) !important;
  object-fit: contain;
  object-position: top left;
}
.area--one .unit.wide .part.tiny {
  padding-right: 44.88188976%;
  box-sizing: border-box;
}
/*# sourceMappingURL=./screen-large.css.map */